import React, { useMemo } from 'react';
import type { GetStaticProps, NextPage } from 'next';

import {
  init,
  graphQLRequest,
  getError404Props,
  ERROR_404_QUERY,
} from '@/lib/graphql';
import { get404MetaTags } from '@/utils/meta';
import { Error404 } from '@/components/pages';
import type { Error404Data } from '@/lib/graphql';

type Props = {
  data: Error404Data;
};

const Custom404: NextPage<Props> = React.memo(function Custom404({ data }) {
  const error404Props = useMemo(() => getError404Props(data), [data]);
  let heading = 'Find the support needed need to plan the rest of your life.';
  if (data.homePage.HomeHeader && data.homePage.HomeHeader.heading) {
    heading = data.homePage.HomeHeader.heading;
  }
  return (
    <>
      {get404MetaTags(heading)}
      <Error404 {...error404Props} />
    </>
  );
});

export const getStaticProps: GetStaticProps<Props> = async () => {
  const response = await graphQLRequest(init(), ERROR_404_QUERY);
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  return {
    props: {
      data: response.data as Error404Data,
    },
    revalidate: 1,
  };
};

export default Custom404;
